import * as fs from "fs";
import { ViteSSG } from "vite-ssg";
import { nextTick } from "vue";

// styles

import "./assets/styles/tailwind.css";

import VueNextSelect from "vue-next-select";
import "./assets/styles/vue-next-select.css";
import VueGTag from "vue-gtag";
import axios from "axios";
import VueCookies from "vue-cookies";
import * as Sentry from "@sentry/vue";
import posthog from "posthog-js";

// mouting point for the whole app

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime.js";
import utc from "dayjs/plugin/utc.js";
import { store, utils } from "common-frontend";
import App from "./App.vue";

// router

import routes from "./router";

const { apiPlugin, posthogPlugin, keysToCamel } = utils;

dayjs.extend(relativeTime);
dayjs.extend(utc);

// vite-ssg: `export const createApp` is required instead of the original `createApp(App).mount('#app')`
export const createApp = ViteSSG(
  App,
  // vue-router options
  {
    routes,
    scrollBehavior() {
      // always scroll to top
      return { top: 0 };
    },
  },
  // function to have custom setups
  ({ app, router, initialState }) => {
    router.afterEach((to, from) => {
      /* eslint-disable */
      if (!import.meta.env.SSR && !window.doNotTrack && window._hsq) {
        window._hsq.push(["setPath", to.path]);
        _hsq.push(["trackPageView"]);
      }
      if (from.name && !import.meta.env.SSR) {
        nextTick(() => {
          posthog.capture("$pageview", {
            $current_url: import.meta.env.VITE_BASE_URL + to.fullPath
          });
        });
      }
      /* eslint-enable */
    });

    // Force a browser reload of whole page if dynamic module names have changed
    router.onError((error, to) => {
      if (!import.meta.env.SSR) {
        const reloadCount =
          parseInt(app.$cookies.get("module_reload_count"), 10) || 0;
        if (
          error.message.includes("Failed to fetch dynamically imported module")
        ) {
          if (reloadCount < 4) {
            app.$cookies.set("module_reload_count", reloadCount + 1, "5s");
            setTimeout(() => {
              window.location = to.fullPath;
            }, 3000);
          } else {
            throw error;
          }
        }
      }
    });

    app.use(apiPlugin);
    app.use(store);
    app.use(router);

    if (import.meta.env.SSR) {
      initialState.store = store.state;
    } else if (initialState.store) {
      store.replaceState(initialState.store);
    }

    // app.config.globalProperties.$useHead = useHead;
    if (!import.meta.env.SSR) {
      if (import.meta.env.VITE_SENTRY_DSN !== "null") {
        const integrations = [
          Sentry.browserTracingIntegration({ router }),
          Sentry.replayIntegration({
            networkDetailAllowUrls: [window.location.origin],
          }),
        ];
        Sentry.init({
          app,
          dsn: import.meta.env.VITE_SENTRY_DSN,
          integrations,
          tracePropagationTargets: [import.meta.env.VITE_BASE_URL, /^\//],
          tracesSampleRate: 0.1,
          logErrors: true,
          trackComponents: true,
          replaysSessionSampleRate: 1.0,
          replaysOnErrorSampleRate: 1.0,
        });
      }
      app.use(posthogPlugin);
    }
    app.component("VueSelect", VueNextSelect);
    if (!import.meta.env.SSR && !window.doNoTrack) {
      app.use(
        VueGTag,
        {
          config: { id: import.meta.env.VITE_GOOGLE_ID },
          includes: [{ id: import.meta.env.VITE_GOOGLE_ADS_ID }],
        },
        router,
      );
    }

    app.config.globalProperties.$dayjs = dayjs;
    app.use(VueCookies, { expires: "7d" });
    store.$app = app;
  },
);

function setUpAppYaml(urls) {
  const replacementToken = "# [dynamic url replacement]";

  fs.readFile("./app.yaml.template", "utf8", (err, data) => {
    if (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      return;
    }

    const profileUrls = urls
      .filter((url) => url.indexOf("/me/") === 0)
      .map((url) => url.replace("/me/", ""));
    const expertAreaUrls = urls
      .filter((url) => url.indexOf("/expert-areas/") === 0)
      .map((url) => url.replace("/expert-areas/", ""));
    const disciplineUrls = urls
      .filter((url) => url.indexOf("/solutions/by-discipline/") === 0)
      .map((url) => url.replace("/solutions/by-discipline/", ""));
    const institutionUrls = urls
      .filter((url) => url.indexOf("/experts-from/") === 0)
      .map((url) => url.replace("/experts-from/", ""));
    const researchCommunityUrls = urls
      .filter((url) => url.indexOf("/research-communities/") === 0)
      .map((url) => url.replace("/research-communities/", ""));

    const newPart = `- url: /expert-areas/(${expertAreaUrls
      .join("|")
      .replaceAll(".", "\\.")
      .replaceAll("/", "\\/")})$
  static_files: dist/expert-areas/\\1.html
  upload: dist/expert-areas/(${expertAreaUrls
    .join("|")
    .replaceAll(".", "\\.")
    .replaceAll("/", "\\/")}).html$
- url: /solutions/by-discipline/(${disciplineUrls.join("|")})$
  static_files: dist/solutions/by-discipline/\\1.html
  upload: dist/solutions/by-discipline/(${disciplineUrls.join("|")}).html$
- url: /research-communities/(${researchCommunityUrls.join("|")})$
  static_files: dist/research-communities/\\1.html
  upload: dist/research-communities/(${researchCommunityUrls.join("|")}).html$
- url: /experts-from/(${institutionUrls
      .join("|")
      .replaceAll(".", "\\.")
      .replaceAll("/", "\\/")})$
  static_files: dist/experts-from/\\1.html
  upload: dist/experts-from/(${institutionUrls
    .join("|")
    .replaceAll(".", "\\.")
    .replaceAll("/", "\\/")}).html$
- url: /me/(${profileUrls.join("|")})$
  static_files: dist/me/\\1.html
  upload: dist/me/(${profileUrls.join("|")}).html$`;

    const start = data.indexOf(replacementToken);
    const end = start + replacementToken.length;

    fs.writeFileSync(
      `./app.yaml`,
      data.substring(0, start) + newPart + data.substring(end, data.length),
    );
  });
}

export async function includedRoutes(paths) {
  // Sensitive key is managed by Vite - this would not be available inside
  // vite.config.js as it runs before the environment has been populated.

  const clearPaths = paths.filter((path) => {
    return path.indexOf(":") === -1;
  });

  const urls = await axios
    .get(
      `${
        import.meta.env.VITE_API_BASE_URL
      }/prerender-research-interest-routes/`,
    )
    .then((interestResp) => {
      const researchInterestData = keysToCamel(interestResp.data);

      return axios
        .get(
          `${
            import.meta.env.VITE_API_BASE_URL
          }/prerender-research-community-routes/`,
        )
        .then((researchCommunityResp) => {
          const researchCommunityData = keysToCamel(researchCommunityResp.data);

          return axios
            .get(
              `${
                import.meta.env.VITE_API_BASE_URL
              }/prerender-institution-routes/`,
            )
            .then((institutionResp) => {
              const institutionData = keysToCamel(institutionResp.data);

              return axios
                .get(
                  `${
                    import.meta.env.VITE_API_BASE_URL
                  }/prerender-profile-routes/`,
                )
                .then((profileResp) => {
                  const profileData = keysToCamel(profileResp.data);
                  return [
                    ...clearPaths,
                    ...researchInterestData.routes,
                    ...researchCommunityData.routes,
                    ...profileData.routes,
                    ...institutionData.routes,
                  ];
                });
            });
        });
    });
  setUpAppYaml(urls);
  return urls;
}
